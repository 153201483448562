import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PostNavigation from "../components/PostNavigation";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../i18n";

const Post = ({ data }) => {
    const { t } = useTranslation();
    const post = data.wpPost;
    const siteTitle = data.wp.generalSettings.title;

    // Define Schema.org JSON-LD for BlogPosting
    const schemaOrg = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        headline: post.title,
        datePublished: post.date,
        dateModified: post.modifiedGmt || post.date,
        author: {
            "@type": "Person",
            name: post.author?.node?.name || siteTitle,
        },
        publisher: {
            "@type": "Organization",
            name: siteTitle,
            logo: {
                "@type": "ImageObject",
                url: "./public/images/logo.webp",
            },
        },
        description: post.excerpt || "",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": `${data.wp.generalSettings.url}${post.uri}`,
        },
        image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || "",
    };

    return (
        <Layout>
            <Seo title={`${post.title} | ${siteTitle}`} />

            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrg)}
                </script>
            </Helmet>

            {/* Header Intro */}
            <section>
                <div className="header-intro theme-bg-primary shadow-sm py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="page-heading mx-auto pb-2">
                                    {post.title}
                                </h2>
                                <ul className="page-heading-post-meta list-inline mb-0">
                                    <li className="list-inline-item mr-3">
                                        <i className="bi bi-clock mr-2"></i>
                                        {post.date} {t("hour")}
                                        {/* {post.modifiedGmt} {t("hour")} */}
                                    </li>
                                    {Array.isArray(post.tags.nodes) &&
                                        post.tags.nodes.length > 0 && (
                                            <li className="list-inline-item mr-3">
                                                <i className="bi bi-tags mr-2"></i>
                                                {post.tags.nodes.map(
                                                    (tag, index) => [
                                                        index > 0 && ", ",
                                                        <Link
                                                            className="text-primary"
                                                            key={index}
                                                            to={tag.link}
                                                        >
                                                            {tag.name}
                                                        </Link>,
                                                    ]
                                                )}
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Single Post */}
            <article className="single-post-wrapper blog-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <div className="cover-image text-center mb-4">
                        <figure>
                            <GatsbyImage
                                className="img-fluid"
                                image={getImage(
                                    post.featuredImage?.node?.localFile
                                )}
                                alt={post.featuredImage?.node?.altText}
                            />
                        </figure>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>

                {/* Navigation */}
                <PostNavigation
                    prevPost={data.prevPost}
                    nextPost={data.nextPost}
                />
            </article>
        </Layout>
    );
};

export default Post;

export const postQuery = graphql`
    query ($databaseId: Int!, $nextId: Int, $prevId: Int) {
        wp {
            generalSettings {
                title
            }
        }
        wpPost(databaseId: { eq: $databaseId }) {
            databaseId
            title
            content
            date(formatString: "DD.MM.YYYY | HH:MM")
            uri
            author {
                node {
                    name
                }
            }
            categories {
                nodes {
                    link
                    name
                }
            }
            tags {
                nodes {
                    link
                    name
                }
            }
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 800
                                height: 450
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
        nextPost: wpPost(databaseId: { eq: $nextId }) {
            title
            uri
        }
        prevPost: wpPost(databaseId: { eq: $prevId }) {
            title
            uri
        }
    }
`;

// If we want to add post modified date: modifiedGmt(formatString: "DD.MM.YYYY | HH:MM")