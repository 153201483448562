import * as React from "react";
import { graphql } from "gatsby";
import Hero from "../components/Home/Hero";
import Slider from "../components/Home/Slider";
import About from "../components/Home/About";
import Features from "../components/Home/Features";
import Posts from "../components/Home/Posts";
import ClientLogos from "../components/Home/ClientLogos";
import CallToAction from "../components/Home/CallToAction";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { useTranslation } from "react-i18next";
import "../i18n";

const IndexPage = ({ data }) => {
    const { t } = useTranslation();
    const page = data.wpPage;
    const siteTitle = data.wp.generalSettings.title;
    const posts = data.allWpPost.nodes;
    const postsOnHomePage = data.wp.postsOnHomePage || 4; // Default to 4 if not set
    const limitedPostsData = posts.slice(0, postsOnHomePage);

    // Initialize variables
    let acfHomeSlider = [];
    let acfHero = {};
    let acfAbout = {};
    let acfFeatures = {};
    let acfClientLogos = {};
    let acfCta = {};

    const acfHomePage = page.acfPrimaryLayout?.acfHomePage ?? [];

    // Extracting data from acfHomePage array
    acfHomePage.forEach((section) => {
        if (section.acfSliders) acfHomeSlider = section.acfSliders;
        if (section.acfHeroTitle) acfHero = section;
        if (section.acfAboutTitle) acfAbout = section;
        if (section.acfCards) acfFeatures = { acfCards: section.acfCards };
        if (section.acfPartners)
            acfClientLogos = { acfPartners: section.acfPartners };
        if (section.acfCtaTitle) acfCta = section;
    });

    const featuresData = acfFeatures.acfCards
        ? acfFeatures.acfCards.map((card) => ({
              icon: card.acfFeaturesIcon,
              title: card.acfFeaturesTitle,
              items: card.acfFeaturesLst
                  ? card.acfFeaturesLst.acfFeaturesListItems.map(
                        (item) => item.acfFeaturesListItemsText
                    )
                  : [],
          }))
        : [];

    const clientsData = acfClientLogos.acfPartners
        ? acfClientLogos.acfPartners.map((partner) => ({
              publicUrl: partner.acfClientLogosLogo?.publicUrl,
              logoUrl: partner.acfClientLogosUrl,
              altText: partner.acfClientLogosLogo?.altText,
          }))
        : [];

    return (
        <Layout>
            <Seo
                title={`${t("siteTitle")} | ${siteTitle}`}
                description={t("metaDesc")}
            />

            {/* Slider Section */}
            <section>
                <Slider slides={acfHomeSlider} />
            </section>

            {/* Hero Section */}
            <section>
                <Hero
                    title={acfHero.acfHeroTitle}
                    description={acfHero.acfHeroDesc}
                    btnName={acfHero.acfHeroContactBtnName}
                    btnUrl={acfHero.acfHeroContactBtnUrl}
                    featuredImage={page.featuredImage}
                />
            </section>

            {/* About Section */}
            <section>
                <About
                    title={acfAbout.acfAboutTitle}
                    description={acfAbout.acfAboutDesc}
                />
            </section>

            {/* Features Section */}
            <section>
                <Features features={featuresData} />
            </section>

            {/* Posts Section */}
            <article>
                <Posts posts={limitedPostsData} />
            </article>

            {/* Partners Section */}
            <section className="theme-bg-primary">
                <ClientLogos clientLogos={clientsData} />
            </section>

            {/* CTA Section */}
            <section className="bg-primary text-white">
                <CallToAction
                    title={acfCta.acfCtaTitle}
                    description={acfCta.acfCtaDesc}
                    btnName={acfCta.acfCtaBtnName}
                    featuredImage={page.featuredImage}
                />
            </section>
        </Layout>
    );
};

export default IndexPage;

export const indexQuery = graphql`
    query {
        wp {
            generalSettings {
                title
            }
            postsOnHomePage
        }
        wpPage(slug: { eq: "home" }) {
            title
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 170
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, PNG]
                            )
                        }
                    }
                }
            }
            acfPrimaryLayout {
                acfHomePage {
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfHomeSlider {
                        fieldGroupName
                        acfSliders {
                            acfSliderImage {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 1920
                                            height: 650
                                            placeholder: BLURRED
                                            formats: [AUTO, WEBP, PNG]
                                        )
                                    }
                                }
                            }
                            acfSliderTitle
                            acfSliderDesc
                            acfSliderBtnUrl
                            acfSliderBtnName
                        }
                    }
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfHero {
                        acfHeroTitle
                        acfHeroDesc
                        acfHeroContactBtnUrl
                        acfHeroContactBtnName
                    }
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfAbout {
                        acfAboutTitle
                        acfAboutDesc
                    }
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfFeatures {
                        acfCards {
                            acfFeaturesIcon
                            acfFeaturesTitle
                            acfFeaturesLst {
                                acfFeaturesListItems {
                                    acfFeaturesListItemsText
                                }
                            }
                        }
                    }
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfClientLogos {
                        acfPartners {
                            acfClientLogosUrl
                            acfClientLogosLogo {
                                publicUrl
                                sourceUrl
                                altText
                            }
                        }
                    }
                    ... on WpPage_Acfprimarylayout_AcfHomePage_AcfCta {
                        acfCtaTitle
                        acfCtaDesc
                        acfCtaBtnName
                    }
                }
            }
        }
        allWpPost(sort: { fields: date, order: DESC }) {
            nodes {
                id
                title
                excerpt
                slug
                date(formatString: "DD.MM.YYYY")
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800
                                    height: 470
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
