import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./PostArchives.module.css";
import { useTranslation } from "react-i18next";
import "../i18n";

// Simple Banner Component
const Banner = ({ isLarge }) => (
    <div
        className={`col-12 ${
            isLarge ? "col-md-12 col-lg-8" : "col-md-6 col-lg-4"
        } mb-5`}
    >
        <div className="card rounded-0 border-0 shadow-sm eq-height">
            <div className="card-body pb-4">
                <h3 className="card-title fw-bold mb-2">Списание "Кармичният кръг"</h3>
                <div className="card-text">
                    <p>
                        Прочетете интересни статии и хороскопи в&nbsp;
                        <a 
                            href="https://www.karm-krag.com/" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-decoration-none fw-bold"
                        >
                            списание „Кармичният кръг“
                        </a>.
                    </p>
                    <p>Посетете сайта на авторското списание на <strong>Светлана Тилкова - Алена</strong> и се потопете в света на кармата, духовността и езотериката.</p>
                </div>
            </div>
        </div>
    </div>
);

const PostArchives = ({ posts }) => {
    const { t } = useTranslation();

    // Separate sticky posts from regular posts
    const stickyPosts = posts.filter((post) => post.isSticky);
    const regularPosts = posts.filter((post) => !post.isSticky);

    // Combine sticky and regular posts
    const postsToShow = stickyPosts.concat(regularPosts);

    // Determine the number of empty spaces and banners needed
    const totalPosts = postsToShow.length;
    let emptySpaces = 0;
    if (totalPosts % 3 === 1) {
        emptySpaces = 2;
    } else if (totalPosts % 3 === 2) {
        emptySpaces = 1;
    }

    return (
        <div className="container blog-cards">
            <div className="row">
                {stickyPosts.map((post, index /* Sticky Posts */) => (
                    <React.Fragment key={`sticky-${index}`}>
                        <div className="col-12 mt-5">
                            <div class="">
                                <div className="featured-card-image card-img-container position-relative d-md-table-cell">
                                    <figure className={styles.figure}>
                                        <GatsbyImage
                                            image={getImage(
                                                post.featuredImage?.node
                                                    ?.localFile
                                            )}
                                            style={{
                                                height: "470px",
                                                width: "100%",
                                                margin: "-1.55rem 0",
                                                position: "cover !important",
                                            }}
                                            imgStyle={{ objectFit: "cover" }}
                                            alt="Descriptive alt text"
                                        />
                                    </figure>
                                    <span className="badge badge-success mb-2 position-absolute">
                                        {t("labelNew")}
                                    </span>

                                    <div className="card-img-overlay overlay-mask text-center p-0">
                                        <Link
                                            className="overlay-mask-link position-absolute w-100 h-100"
                                            to={`/blog${post.uri}`}
                                        ></Link>
                                    </div>
                                </div>
                                <div className="featured-card-body card-body d-md-table-cell pb-4 bg-white shadow-sm">
                                    <h3 className="card-title fw-bold mb-2">
                                        <Link to={`/blog${post.uri}`}>
                                            {post.title}
                                        </Link>
                                    </h3>
                                    <div className="card-text">
                                        <ul className="meta list-inline my-3">
                                            <li className="list-inline-item mr-3">
                                                <i className="bi bi-clock mr-2"></i>
                                                {new Date(
                                                    post.date
                                                ).toLocaleDateString("bg-BG", {
                                                    month: "long",
                                                    day: "numeric",
                                                    year: "numeric",
                                                })}
                                            </li>

                                            {Array.isArray(
                                                post.categories.nodes
                                            ) &&
                                                post.categories.nodes.length >
                                                    0 && (
                                                    <li className="list-inline-item me-3">
                                                        <i className="bi bi-bookmark mr-2"></i>
                                                        {post.categories.nodes.map(
                                                            (
                                                                category,
                                                                index
                                                            ) => [
                                                                index > 0 &&
                                                                    ", ",
                                                                <Link
                                                                    key={index}
                                                                    to={
                                                                        category.link
                                                                    }
                                                                >
                                                                    {
                                                                        category.name
                                                                    }
                                                                </Link>,
                                                            ]
                                                        )}
                                                    </li>
                                                )}

                                            {Array.isArray(post.tags.nodes) &&
                                                post.tags.nodes.length > 0 && (
                                                    <li className="list-inline-item mr-3">
                                                        <i className="bi bi-tags mr-2"></i>
                                                        {post.tags.nodes.map(
                                                            (tag, index) => [
                                                                index > 0 &&
                                                                    ", ",
                                                                <Link
                                                                    key={index}
                                                                    to={
                                                                        tag.link
                                                                    }
                                                                >
                                                                    {tag.name}
                                                                </Link>,
                                                            ]
                                                        )}
                                                    </li>
                                                )}
                                        </ul>
                                        <div className="excerpt mb-3">
                                            <p
                                                align="justify"
                                                dangerouslySetInnerHTML={{
                                                    __html: post.excerpt,
                                                }}
                                            />
                                        </div>
                                        <Link
                                            className="btn btn-primary d-none d-md-inline-block"
                                            to={`/blog${post.uri}`}
                                        >
                                            {t("readMore")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}

                <div className="col-12 my-5">
                    <div className="blog-archive-title">
                        <h2>{t("archive")}</h2>
                    </div>
                </div>

                {postsToShow.map((post, index) => (
                    <div
                        key={`regular-${index}`}
                        className="col-12 col-md-6 col-lg-4 mb-5"
                    >
                        <div className="card rounded-0 border-0 shadow-sm eq-height">
                            <div className="card-img-container position-relative">
                                {post.featuredImage && (
                                    <figure className={styles.figure}>
                                        <GatsbyImage
                                            className="card-img-top rounded-0"
                                            image={getImage(
                                                post.featuredImage?.node
                                                    ?.localFile
                                            )}
                                            alt={
                                                post.featuredImage?.node
                                                    ?.altText
                                            }
                                        />
                                    </figure>
                                )}
                                <div className="card-img-overlay overlay-mask text-center p-0">
                                    <div className="overlay-mask-content text-center w-100 position-absolute">
                                        <Link
                                            className="btn btn-primary"
                                            to={`/blog${post.uri}`}
                                        >
                                            {t("readMore")}
                                        </Link>
                                    </div>
                                    <Link
                                        className="overlay-mask-link position-absolute w-100 h-100"
                                        to={`/blog${post.uri}`}
                                    ></Link>
                                </div>
                            </div>
                            <div className="card-body pb-4">
                                <h3 className="card-title fw-bold mb-2">
                                    <Link to={`/blog${post.uri}`}>
                                        {post.title}
                                    </Link>
                                </h3>
                                <div className="card-text">
                                    <ul className="meta list-inline mb-1">
                                        {Array.isArray(post.categories.nodes) &&
                                            post.categories.nodes.length >
                                                0 && (
                                                <li className="list-inline-item me-3">
                                                    <i className="bi bi-bookmark mr-2"></i>
                                                    {post.categories.nodes.map(
                                                        (category, index) => [
                                                            index > 0 && ", ",
                                                            <Link
                                                                key={index}
                                                                to={
                                                                    category.link
                                                                }
                                                            >
                                                                {category.name}
                                                            </Link>,
                                                        ]
                                                    )}
                                                </li>
                                            )}

                                        {Array.isArray(post.tags.nodes) &&
                                            post.tags.nodes.length > 0 && (
                                                <li className="list-inline-item mr-3">
                                                    <i className="bi bi-tags mr-2"></i>
                                                    {post.tags.nodes.map(
                                                        (tag, index) => [
                                                            index > 0 && ", ",
                                                            <Link
                                                                key={index}
                                                                to={tag.link}
                                                            >
                                                                {tag.name}
                                                            </Link>,
                                                        ]
                                                    )}
                                                </li>
                                            )}
                                    </ul>
                                </div>

                                <div className="excerpt secondary">
                                    <p>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt,
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="card-footer border-0">
                                <ul className="meta list-inline mb-0">
                                    <li className="list-inline-item">
                                        <i className="bi bi-clock mr-2"></i>
                                        {new Date(post.date).toLocaleDateString(
                                            "bg-BG",
                                            {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                            }
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
                {Array.from({ length: emptySpaces }).map((_, index) => (
                    <Banner key={`banner-${index}`} />
                ))}
            </div>
        </div>
    );
};

export default PostArchives;
