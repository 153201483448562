import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./Slider.module.css";

const Slider = ({ slides }) => {
    return (
        <div
            id="carouselExampleCaptions"
            className={`carousel slide ${styles.sliderSection}`}
        >
            <div className="carousel-indicators">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current={index === 0 ? "true" : undefined}
                        aria-label={`Slide ${index + 1}`}
                    />
                ))}
            </div>
            <div className={`carousel-inner ${styles.carouselItemCustom}`}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`carousel-item ${
                            index === 0 ? "active" : ""
                        } ${styles.carouselItemCustom}`}
                    >
                        <div className={styles.carouselMaskCustom}></div>
                        <GatsbyImage
                            className="d-block w-100"
                            image={
                                slide.acfSliderImage?.localFile
                                    ? getImage(slide.acfSliderImage.localFile)
                                    : null
                            }
                            alt={
                                slide.acfSliderImage?.altText ||
                                "Default alt text"
                            }
                        />

                        <div
                            className={`carousel-caption ${styles.carouselCaptionCustom}`}
                        >
                            <h5>{slide.acfSliderTitle}</h5>
                            <p>{slide.acfSliderDesc}</p>
                            {slide.acfSliderBtnName &&
                                slide.acfSliderBtnUrl && (
                                    <a
                                        href={slide.acfSliderBtnUrl}
                                        className="btn btn-outline-light mb-4"
                                        rel="noopener noreferrer"
                                    >
                                        {slide.acfSliderBtnName}
                                    </a>
                                )}
                        </div>
                    </div>
                ))}
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
            >
                <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
            >
                <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default Slider;
